export const GET_CONTACTS = 'GET_CONTACTS';
export const GET_CONTACT  = 'GET_CONTACT';
export const GET_SHOPS = 'GET_SHOPS';
export const ADD_CONTACT = 'ADD_CONTACT';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const SET_CURRENT = 'SET_CURRENT';
export const CLEAR_CURRENT = 'CLEAR_CURRENT';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const FILTER_CONTACTS = 'FILTER_CONTACTS';
export const CLEAR_CONTACTS = 'CLEAR_CONTACTS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CONTACT_ERROR = 'CONTACT_ERROR';
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const SEARCH_SHOPS = 'SEARCH_SHOPS'
export const GET_SHOP = 'GET_SHOP'
export const CLEAR_SHOPS = 'CLEAR_SHOPS'
export const SET_LOADING = 'SET_LOADING'
export const ADD_SHOP = 'ADD_SHOP';
export const DELETE_SHOP = 'DELETE_SHOP';
export const UPDATE_SHOP = 'UPDATE_SHOP';
export const FILTER_SHOPS = 'FILTER_SHOPS';
export const SHOP_ERROR = 'SHOP_ERROR';