import React from 'react';

const About = () => {
  return (
    <div>
      <h1>About This App</h1>
      <p className='my-1'>
        Pickk Shop is a shopping Hall where peolpe can find all they could buy and make good contacts with buyers and sellers directly.
      </p>
      <p className='bg-dark p'>
        <strong>Version: </strong> 1.0.0
      </p>
    </div>
  );
};

export default About;
